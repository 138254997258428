import { IonIcon, IonButton, IonLabel } from '@ionic/react';
import { removeCircleOutline, addCircleOutline } from 'ionicons/icons';
import React, { useRef, useState, useEffect } from 'react';
import { isMobile } from '../HelperUtils';
import { getValueAndUnitFromLocator } from '../InspectionLogic';
import { UserInputLocator } from '../InspectionModel';
import { safeInputToFloat } from '../Model';
import { FloatMeasurement, IntMeasurement } from '../generated/openapi/core';
import { renderUnit } from '../ServiceInspection';
import './CGranularity.scss';
import { useInspectionContext } from '../context/InspectionContextProvider';
import { CInput } from './CInput';

// --------------------------------------------------------------------------------------------------------
// CGranularity
// --------------------------------------------------------------------------------------------------------
interface CCGranularity {
  measurement: FloatMeasurement | IntMeasurement;
  id: string;
  index: number;
  inputLocator: UserInputLocator;
  showDisplayName?: boolean;
}

export const CGranularity = (props: CCGranularity) => {
  const { inspection, updateInspection } = useInspectionContext();
  const value = getValueAndUnitFromLocator(props.inputLocator, inspection).value;

  const [showInput, setShowInput] = useState<boolean>(false);

  const toggleInput = () => setShowInput(!showInput);

  return showInput ? (
    <CInput
      id={props.id}
      index={props.index}
      locator={props.inputLocator}
      measurement={props.measurement}
      showDisplayName={props.showDisplayName}
      toggleInput={toggleInput}
    />
  ) : (
    <div className="c-granularity">
      <PlusMinusButtons
        measurement={props.measurement}
        currentValue={value}
        updateAssessmentFunc={(v) => updateInspection(v, props.inputLocator)}
        toggleInput={toggleInput}
      />
    </div>
  );
};

// ----------------------------------------------------------------
// Buttons that allow longPress and + and -
// ----------------------------------------------------------------

const LongPressButton = ({
  icon,
  cancelLongPress,
  onLongPress,
  val,
  setVal,
  increment,
}) => {
  let ts = useRef(0);
  let isM = isMobile();
  return (
    <div
      onTouchEnd={(e) => {
        e.stopPropagation();
        cancelLongPress();
      }}
      onTouchMove={(e) => {
        e.stopPropagation();
        let te = e.touches[0].clientY;
        if (ts.current > te + 15 || ts.current < te - 15) {
          cancelLongPress();
        }
      }}
      onTouchStart={(e) => {
        e.stopPropagation();
        ts.current = e.touches[0].clientY;
        onLongPress(val, setVal, increment);
      }}
      onMouseUp={(e) => {
        // both touch and mouse events are fired on mobile,
        // that's why be have the (isMobile) flag to prevent the action
        // from firing twice
        if (isM) {
          return;
        }
        e.stopPropagation();
        cancelLongPress();
      }}
      onMouseLeave={(e) => {
        if (isM) {
          return;
        }
        e.stopPropagation();
        cancelLongPress();
      }}
      onMouseDown={(e) => {
        if (isM) {
          return;
        }
        e.stopPropagation();
        onLongPress(val, setVal, increment);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Space' || e.key === 'Enter') {
          e.stopPropagation();
          onLongPress(val, setVal, increment);
        }
      }}
      onKeyUp={(e) => {
        e.stopPropagation();
        cancelLongPress();
      }}
    >
      <IonButton1 color="light" mode="ios">
        <IonIcon icon={icon} />
      </IonButton1>
    </div>
  );
};

const IonButton1 = React.memo(IonButton, (prev, next) => {
  return true;
});

function calcNumericValue(
  prevInputValue: string,
  measurement: FloatMeasurement | IntMeasurement,
  increment: number
) {
  const { maxValue, minValue, granularity } = measurement;

  let value = parseFloat(prevInputValue) ?? minValue ?? maxValue ?? 0;

  if (isNaN(value)) {
    value = minValue ?? 0;
  }

  increment = increment * safeInputToFloat(granularity, 1);
  value += increment;

  if (maxValue != null && maxValue !== 0 && value > maxValue) {
    value = maxValue;
  }
  if (minValue != null && value < minValue) {
    value = minValue;
  }

  return parseFloat(value?.toFixed?.(3) ?? `${value}`);
}

const PlusMinusButtons = ({
  measurement,
  currentValue,
  updateAssessmentFunc,
  toggleInput,
}) => {
  let timer = useRef(null);
  let i = useRef(0);

  let [val1, setVal1] = useState(!isNaN(parseFloat(currentValue)) ? currentValue : 0);
  useEffect(() => {
    setVal1(currentValue);
  }, [currentValue]);

  const onLongPress = async (val, setVal, delta, ms = 200) => {
    i.current += delta;
    setVal(calcNumericValue(val1, measurement, i.current));
    timer.current = setTimeout(
      async () => {
        clearInterval(timer.current);
        onLongPress(val, setVal, delta, ms - 10);
      },
      ms < 10 ? 10 : ms
    );
  };

  const cancelLongPress = async () => {
    const newValue = val1;

    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
    if (i.current === 0) {
      return;
    }
    if (newValue == null) {
      return;
    }
    updateAssessmentFunc(newValue);
    i.current = 0;
  };

  return (
    <>
      <LongPressButton
        cancelLongPress={cancelLongPress}
        onLongPress={onLongPress}
        increment={-1}
        icon={removeCircleOutline}
        val={val1}
        setVal={setVal1}
      />
      <IonLabel
        className="unit-number"
        onClick={toggleInput}
        style={{ cursor: 'pointer' }}
      >
        <span>{val1 ?? '-'}</span>
        <span>{renderUnit(measurement.unit)}</span>
      </IonLabel>
      <LongPressButton
        cancelLongPress={cancelLongPress}
        onLongPress={onLongPress}
        increment={1}
        icon={addCircleOutline}
        val={val1}
        setVal={setVal1}
      />
    </>
  );
};
